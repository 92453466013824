import {
	TOGGLE_FILTER_PANEL, TOGGLE_FILTER,
	FILTER_COLLECTION_PRODUCTS, ACTIVE_FILTERS,
	CLEAR_FILTERS, CLEAR_FILTER, SEARCH, TOGGLE_SEARCH, 
	APPLY_FILTERS
} from "utils/action-types";

const initialState = {
	collections: {},
	tags: {},
	search: "",
	isSearchOpen: false,
	isFiltersOpen: false
};

const filtersReducer = (state = initialState, action) => {
	switch (action.type) {
		case TOGGLE_FILTER_PANEL: {
			return {
				...state,
				isFiltersOpen: !state.isFiltersOpen
			};
		}
		case TOGGLE_FILTER: {
			return {
				...state,
				tags: {
					...state.tags,
					[action.tag]: {
						...state.tags[action.tag],
						isActive: !state.tags[action.tag].isActive,
					}
				}
			};
		}

		case ACTIVE_FILTERS: {
			return {
				...state,
				tags: action.payload
			};
		}
		case FILTER_COLLECTION_PRODUCTS: {
			return {
				...state,
				collections: {
					...state.collections,
					[action.handle]: action.payload
				}
			};
		}
		case CLEAR_FILTERS: {
			return {
				...state,
				tags: {
					...Object.fromEntries(
						Object.entries(state.tags)
							.map(([tag, { displayName }]) => [tag, { displayName, isActive: false, isSettled: false }]))
				}
			};
		}
		case CLEAR_FILTER: {
			return {
				...state,
				tags: {
					...state.tags,
					[action.tag]: {
						...state.tags[action.tag],
						isActive: false,
						isSettled: false
					}
				}
			};
		}
		case SEARCH: {
			return {
				...state,
				search: action.search
			};
		}
		case TOGGLE_SEARCH: {
			return {
				...state,
				isSearchOpen: !state.isSearchOpen
			};
		}

		case APPLY_FILTERS: {
			return {
				...state,
				tags: Object.entries(state.tags).reduce((acc, [tag, tagData]) => {
					acc[tag] = { ...tagData, isSettled: tagData.isActive };
					return acc;
				}, {})
			};
		}
		default:
			return state;
	}
};

export default filtersReducer;
