import {
	customerTokenKey, customerSessionKey, STORE_ID, SHOPIFY_API_VERSION
} from "utils/constants";
import { isValidToken, getCustomerAccessToken } from "utils/oauth";
import navigate from "helpers/navigate";
import axios from "axios";
import {
	GET_CUSTOMER, GET_CUSTOMER_ADDRESSES, UPDATE_CUSTOMER_ADDRESS, CHANGE_DEFAULT_ADDRESS,
	CREATE_CUSTOMER_ADDRESS, DELETE_CUSTOMER_ADDRESS, GET_CUSTOMER_ORDERS, UPDATE_CUSTOMER_METAFIELDS
} from "utils/action-types";
import {
	customerQuery, personalAddressesQuery, customerAddressCreateMutation,
	customerAddressDeleteMutation, customerAddressUpdateMutation, changeDefaultAddressMutation,
	ordersQuery, metafieldsSetMutation
} from "./queries";

class CustomerResource {
	constructor() {
		this.customerApiCall = null;
		this.client = axios.create({
			baseURL: `https://shopify.com/${STORE_ID}/account/customer/api/${SHOPIFY_API_VERSION}/graphql`,
			headers: { "Content-Type": "application/json" },
		});
		this.customerApiCall = async (operationName, query, variables = {}) => {
			const token = getCustomerAccessToken();
			if (!isValidToken(token)) return;

			return this.client.post("", { operationName, query, variables }, {
				headers: { Authorization: token?.access_token }
			})
				.then(response => {
					if (response.status === 200) return Object.values(response.data.data)[0];
					else return null;
				})
				.catch(error => {
					console.error({ error: error.response?.data });
					if (error.response?.status === 401) {
						window.localStorage.removeItem(customerTokenKey);
						navigate("/account/login");
					}
					return null;
				});
		};
	}

	async getProfile() {
		const customer = await this.customerApiCall(GET_CUSTOMER, customerQuery);
		if (customer) {
			this.setInSession(customer);
			return customer;
		}

		return null;
	}

	getPersonalAddresses(cursor) {
		const afterCursorEl = cursor ? `after: "${cursor},"` : "";
		return this.customerApiCall(GET_CUSTOMER_ADDRESSES, personalAddressesQuery(afterCursorEl));
	}

	updateAddress(addressId, address, defaultAddress) {
		return this.customerApiCall(UPDATE_CUSTOMER_ADDRESS, customerAddressUpdateMutation, { addressId, address, defaultAddress });
	}

	createAddress(address, defaultAddress) {
		return this.customerApiCall(CREATE_CUSTOMER_ADDRESS, customerAddressCreateMutation, { address, defaultAddress });
	}

	deleteAddress(addressId) {
		return this.customerApiCall(DELETE_CUSTOMER_ADDRESS, customerAddressDeleteMutation, { addressId });
	}

	changeDefaultAddress(addressId) {
		return this.customerApiCall(CHANGE_DEFAULT_ADDRESS, changeDefaultAddressMutation, { addressId, defaultAddress: true });
	}

	getOrders(cursor) {
		const afterCursorEl = cursor ? `after: "${cursor},"` : "";
		return this.customerApiCall(GET_CUSTOMER_ORDERS, ordersQuery(afterCursorEl));
	}

	getOrdersFromAdmin = async (customerId, cursor) => {
		const afterCursorEl = cursor ? `after: "${cursor},"` : "";

		const response = await axios("/.netlify/functions/admin-api-orders", {
			method: "POST",
			data: {
				customerId,
				afterCursorEl,
			},
		});
		return response.data;
	};

	async updateMetafields(key, discountCode, id, userMetafields) {
		const metafield = userMetafields.find(mf => mf?.key === key);
		const existingDiscountCodes = metafield ? JSON.parse(metafield.value) : [];

		if (!existingDiscountCodes.includes(discountCode)) {
			existingDiscountCodes.push(discountCode);
		}

		const metafields = [{ "key": key, "namespace": "custom", "ownerId": id, "value": JSON.stringify(existingDiscountCodes) }];

		const response = await this.customerApiCall(UPDATE_CUSTOMER_METAFIELDS, metafieldsSetMutation, { metafields });

		return response;
	}

	setInSession(customer) {
		if (!customer) return;
		sessionStorage.setItem(customerSessionKey, JSON.stringify(customer));
	}
}

export default CustomerResource;