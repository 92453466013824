import { customerTokenKey } from "utils/constants";

const encodeBase64URL = (buffer) =>
	btoa(String.fromCharCode.apply(null, new Uint8Array(buffer)))
		.replace(/\+/g, "-")
		.replace(/\//g, "_")
		.replace(/=/g, "");

const generateSecureRandomString = (length) => {
	if (typeof window === "undefined") {
		throw new Error("window object is not available");
	}

	const randomBytes = new Uint8Array(length);
	window.crypto.getRandomValues(randomBytes);
	return randomBytes;
};

export const createCodeVerifier = () => encodeBase64URL(generateSecureRandomString(32));

export const createCodeChallenge = async (verifier) => {
	if (typeof window === "undefined") {
		throw new Error("window object is not available");
	}

	const encoder = new TextEncoder();
	const encodedVerifier = encoder.encode(verifier);
	const hash = await window.crypto.subtle.digest("SHA-256", encodedVerifier);
	return encodeBase64URL(hash);
};

export const setCustomerAccessToken = (token) =>
	window.localStorage.setItem(customerTokenKey, !token ? "" : JSON.stringify(token));

export const getCustomerAccessToken = () => JSON.parse(window.localStorage.getItem(customerTokenKey));

export const isValidToken = (token) => {
	if (token?.access_token) {
		const currentTime = new Date().getTime();
		const expiresAt = new Date(token.expires_at).getTime();
		if (currentTime < expiresAt)
			return true;
	}
	return false;
};
