import ModalRoutingContext from "./src/context/modal-routing";
import Modal from "components/modal";
import { navigate } from "gatsby";
import React, { useState, useEffect } from "react";
import { GlobalProvider } from "context/global";
import { getLanguageCode } from "utils/language";

const withoutPrefix = (path) => {
	const prefix = typeof __BASE_PATH__ !== `undefined` ? __BASE_PATH__ : __PATH_PREFIX__;

	return path.slice(prefix ? prefix.length : 0);
};

const Wrapper = ({ path, children, pageContext }) => {
	// Do not render the global provider for the preview page, as it is already wrapped in the PreviewPage component
	if (path === "/preview/:documentType/:language/:documentId")  
		return <>{children}</>

	const {
		language, settings, subscriptionSettings, isSubscriptionPage, isOneTimeCollection, isCollection
	} = pageContext;

	if (typeof settings?.subscriptionSettings === "object" && typeof subscriptionSettings === "object") {
		settings.subscriptionSettings = {
			...settings.subscriptionSettings, ...subscriptionSettings
		};
	}

	const langCode = getLanguageCode(language);

	return (
		<GlobalProvider value={{
			...settings, language, langCode, isSubscriptionPage, isOneTimeCollection, isCollection
		}}>
			{children}
		</GlobalProvider>
	);
}

const wrapPageElement = ({ element, props }) => {
	const [lastPage, setLastPage] = useState(null);

	useEffect(() => {
		if (props.location.pathname !== lastPage?.props?.location.pathname && !props.location.state?.modal) {
			setLastPage(element);
		}

		window.__LANGUAGE = props.pageContext.language;
	}, [props.location.pathname]);

	const handleRequestClose = () => {
		navigate(withoutPrefix(lastPage?.props.location.pathname), {
			state: {
				preserveScroll: true,
			},
		});
	};

	const isModal = lastPage && props.location.state?.modal;

	const pageElement = isModal ? (
		<lastPage.type {...lastPage.props} key={lastPage.props?.pageResources?.page?.path} />
	) : (
		<element.type {...props} key={props.pageResources.page.path} />
	);

	let modalElement = null;

	if (isModal) {
		modalElement = <element.type {...props} key={props.pageResources.page.path} />;
	} else if (lastPage) {
		modalElement = <lastPage.type {...lastPage.props} key={lastPage.props.pageResources.page.path} />;
	}

	return (
		<Wrapper path={props.path} pageContext={props.pageContext}>
			{pageElement}
			<Modal
				isOpen={!!isModal}
				close={handleRequestClose}
				title={props?.location?.state?.title}
			>
				{modalElement ? (
					<React.Fragment key={props.location.key}>
						<ModalRoutingContext.Provider
							value={{
								isModal: true,
								closeTo: lastPage ? () => handleRequestClose() : navigate("/"),
							}}
						>
							{modalElement}
						</ModalRoutingContext.Provider>
					</React.Fragment>
				) : null}
			</Modal>
		</Wrapper>
	);
};;

export default wrapPageElement;