import React, { createContext, useContext } from "react";

const GlobalContext = createContext();

export const GlobalProvider = ({ children, value }) => (
	<GlobalContext.Provider value={value}>
		{children}
	</GlobalContext.Provider>
);

export const useGlobalContext = () => useContext(GlobalContext) || {
	translation: {}, countries: [], languages: []
};
