export const CURRENT_COUNTRY = process.env.GATSBY_COUNTRY;
export const SHOPIFY_DOMAIN = process.env.GATSBY_SHOPIFY_DOMAIN;
export const ADMIN_API_TOKEN = process.env.GATSBY_ADMIN_API_TOKEN;
export const SANITY_PROJECT_ID = process.env.GATSBY_SANITY_PROJECT_ID;
export const SANITY_DATASET = process.env.GATSBY_SANITY_DATASET;
export const GA4_ID = process.env.GATSBY_GA4_ID;
export const FB_PIXEL_ID = process.env.GATSBY_FB_PIXEL_ID;
export const X_LOOP_TOKEN = process.env.X_LOOP_TOKEN;
export const SITE_URL = process.env.GATSBY_SITE_URL;
export const CHECKOUT_DOMAIN = process.env.GATSBY_CHECKOUT_DOMAIN;
export const IS_PRODUCTION = process.env.NODE_ENV === "production";
export const CURRENCY = process.env.GATSBY_CURRENCY;
export const INVOICE_APP_URL = process.env.GATSBY_INVOICE_APP_URL;
export const INVOICE_MULTIPLY_NR = process.env.GATSBY_INVOICE_MULTIPLY_NR;
export const SHOPIFY_STOREFRONT_TOKEN = process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN;
export const KLAVIYO_COMPANY_ID = process.env.GATSBY_KLAVIYO_COMPANY_ID;
export const KLAVIYO_B2B_ID = process.env.GATSBY_KLAVIYO_B2B_ID;
export const REVIEWS_STORE = process.env.GATSBY_REVIEWS_STORE;
export const CONVERT_ID = process.env.GATSBY_CONVERT_ID;
export const STORE_ID = process.env.GATSBY_STORE_ID;
export const CLIENT_ID = process.env.GATSBY_CLIENT_ID;
export const LOOP_STOREFRONT_BASE_URL = process.env.GATSBY_LOOP_STOREFRONT_BASE_URL;
export const REDIRECT_URL = process.env.GATSBY_CONTEXT === "deploy-preview" ?
	process.env.GATSBY_DEPLOY_PRIME_URL :
	process.env.GATSBY_LOGIN_REDIRECT_URL;

export const SHOPIFY_API_VERSION = "2024-10";
export const littledataPixelIds = { ga4MeasurementId: GA4_ID, fbPixelId: FB_PIXEL_ID, tiktokPixelId: "1648209194" };

const businessClientLinks = {
	de: "https://every-foods.wholesale.shopifyapps.com/accounts/sign_in",
	ch: "https://every-ch.wholesale.shopifyapps.com",
	nl: "https://every-nl.wholesale.shopifyapps.com",
};

export const BUSINESS_CLIENT_LINK = businessClientLinks[CURRENT_COUNTRY];

const reviewsAccountSurvey = {
	de: {
		widgetId: "mv5WL4JIGqhlNUd0",
		storeName: "every-foods.com",
		campaignId: "1420",
		lang: "de"
	},
	ch: {
		widgetId: "xEklrL7g2b3uq5H0",
		storeName: "every-foods-ch",
		campaignId: "1779",
		lang: "de"
	},
	nl: {
		widgetId: "oS0vnO21pYbtHyfz",
		storeName: "every-foods.nl-bnkbjml",
		campaignId: "1948",
		lang: "nl"
	}
};

export const REVIEWS_ACCOUNT_SURVEY = reviewsAccountSurvey[CURRENT_COUNTRY];

export const MAIL_ID_REGEX = /CustomerAddress\/(\d+)/;

export const orderStatus = (translation) => ({
	AUTHORIZED: translation.order_status?.authorized,
	PENDING: translation.order_status?.pending,
	OVERDUE: translation.order_status?.overdue,
	EXPIRING: translation.order_status?.expiring,
	EXPIRED: translation.order_status?.expired,
	PAID: translation?.order_status?.paid,
	REFUNDED: translation.order_status?.refunded,
	PARTIALLY_REFUNDED: translation.order_status?.partially_refunded,
	PARTIALLY_PAID: translation.order_status?.partially_paid,
	VOIDED: translation.order_status?.voided,
	UNPAID: translation.order_status?.unpaid,
});

export const fullfillmentStatus = (translation) => ({
	FULFILLED: translation.fulfillment_status?.fulfilled,
	UNFULFILLED: translation.fulfillment_status?.unfulfilled,
	PARTIALLY_FULFILLED: translation.fulfillment_status?.partially_fulfilled,
	PENDING_FULFILLMENT: translation.fulfillment_status?.pending_fulfillment,
	IN_PROGRESS: translation.fulfillment_status?.in_progress,
	ON_HOLD: translation.fulfillment_status?.on_hold,
	OPEN: translation.fulfillment_status?.open,
	RESTOCKED: translation.fulfillment_status?.restocked,
	SCHEDULED: translation.fulfillment_status?.scheduled,
});

export const canceledReason = (translation) => ({
	CUSTOMER: translation.canceled_reason?.customer,
	DECLINED: translation.canceled_reason?.declined,
	FRAUD: translation.canceled_reason?.fraud,
	INVENTORY: translation.canceled_reason?.inventory,
	OTHER: translation.canceled_reason?.other,
});

export const CUSTOMER_STATE = {
	Logged_in: "LOGGED_IN",
	Logged_out: "LOGGED_OUT",
};

export const customerTokenKey = "every-custom-access-token";
export const customerSessionKey = "every-customer";
export const loyaltyLionAccessToken = "ll-access-token";
export const DOTS = "...";
export const loopTokenKey = "loopToken";
export const activeBoxKey = "mealBox";
export const frequencyKey = "df";
export const CART_ID = "cartId";
export const freeGiftRemovedKey = "freeGiftRemoved";
export const closedOfferBar = "closedOfferBar";
export const upsellsRemovedKey = "upsellsRemoved";

export const productSingleTranslations = {
	DE: {
		typicalValues: "durchschnittliche Nährwerte 	",
		energy: "Energiegehalt (kj/kcal)	",
		fat: "Fett (g)	",
		fattyAcids: "davon Gesättigte Fettsäuren (g)	",
		carbohydrates: "Kohlenhydrate (g)	",
		ofWhichSuggar: "davon Zucker (g)	",
		dietaryFiber: "Ballaststoffe (g)	",
		protein: "Eiweiß (g)	",
		salt: "Salz (g)	",
	},
	EN: {
		typicalValues: "Typical values",
		energy: "Energy (kj/kcal)	",
		fat: "Fat (g)	",
		fattyAcids: "of which saturated fatty acids  (g)",
		carbohydrates: "Carbohydrates  (g)",
		ofWhichSuggar: "of which sugar  (g)",
		dietaryFiber: "Dietary fiber  (g)",
		protein: "Protein (g)",
		salt: "Salt (g)",
	},
	FR: {
		typicalValues: "Valeurs nutritionnelles moyennes  ",
		energy: "Énergie (kj/kcal)	",
		fat: "Graisse (g)	",
		fattyAcids: "dont aciddes gras saturés  (g)	",
		carbohydrates: "Glucides  (g)	",
		ofWhichSuggar: "dont sucre  (g)	",
		dietaryFiber: "Fibres alimentaires  (g)	",
		protein: "Protéine (g)	",
		salt: "Sel (g)",
	},
	NL: {
		typicalValues: "Gemiddelde voedingswaarden",
		energy: "Energie (kj/kcal)	",
		fat: "Vet (g)		",
		fattyAcids: "hiervan verzadigde vetzuren  (g)	",
		carbohydrates: "Koolhydraten  (g)	",
		ofWhichSuggar: "van welke suikers  (g)	",
		dietaryFiber: "Vezel  (g)	",
		protein: "Eiwit (g)	",
		salt: "Zout (g)	",
	},
	DK: {
		typicalValues: "Gennemsnitlige Næringsværdier  	",
		energy: "Energi (kj/kcal)	",
		fat: "Fedt (g)	",
		fattyAcids: "heraf mættede fedtsyrer  (g)	",
		carbohydrates: "Kulhydrater  (g)	",
		ofWhichSuggar: "heraf sukker  (g)	",
		dietaryFiber: "Kostfibre  (g)	",
		protein: "Protéine (g)	",
		salt: "Sel (g)	",
	},
};

export const languages = ["DE", "EN", "FR", "NL", "DK"];

export const countries = [
	{ code: "CH", url: "https://www.everyfoods.ch", },
	{ code: "LI", url: "https://www.everyfoods.ch" },
	{ code: "DE", url: "https://www.every-foods.com" },
	{ code: "AT", url: "https://www.every-foods.com" },
	{ code: "DK", url: "https://www.every-foods.com" },
	{ code: "LU", url: "https://www.every-foods.nl" },
	{ code: "NL", url: "https://www.every-foods.nl" },
	{ code: "BE", url: "https://www.every-foods.nl" },
];

export const stayInCountry = "stayInCountry";

export const locationIds = {
	"DK": "244656", // Denmark
	"DE": "244656", // Germany
	"AT": "242988", // Austria
	"BE": "254531", // Belgium
	"NL": "242984", // Netherlands
	"LU": "254709", // Luxembourg
	"CH": "242919", // Switzerland
	"LI": "242992", // Lietchenstein
};