import React from "react";
import { default as ReactModal } from "react-modal";
import { ReactComponent as Closy } from "assets/svgs/closy.svg";
import clsx from "clsx/lite";

const Modal = ({ title, isOpen, close, children, className, bodyClassName = "", overlayClassName, headerStyle, hideHeader = false }) =>
	isOpen ? (
		<ReactModal
			isOpen={isOpen}
			onRequestClose={close}
			contentLabel={`${title} modal`}
			className={`styled-modal ${className ?? ""}`}
			overlayClassName={`styled-modal-overlay ${overlayClassName ?? ""}`}
			ariaHideApp={false}
		>
			{!hideHeader && (
				<div className="flex flex-col py-4 mt-5">
					<div className={clsx("flex px-8 md:px-12", headerStyle)}>
						<p className="text-body">{title}</p>
						<button className="p-1 ml-auto" onClick={close}>
							<Closy />
						</button>
					</div>
				</div>
			)}
			{/* TODO: scroll from this div */}
			<div className={clsx(bodyClassName)}>{children}</div>
		</ReactModal>
	) : (
		<></>
	);

export default Modal;
