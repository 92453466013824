module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K8GGZTL","includeInDevelopment":false,"enableWebVitalsTracking":false,"routeChangeEventName":"route.change","defaultDataLayer":{"type":"function","value":"function () {\n\t\t\t\t\treturn {\n\t\t\t\t\t\tevent: \"optimize.activate\",\n\t\t\t\t\t\toriginalLocation: document.location.protocol + \"//\" + document.location.hostname + document.location.pathname + document.location.search,\n\t\t\t\t\t};\n\t\t\t\t}"},"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
