import CartResource from "./cart-resource";
import CustomerResource from "./customer-resource";

/**
 * @class
 *
 * @property {CartResource} cart The property under which cart fetching and mutating methods live.
 * @property {CustomerResource} customer The property under which customer fetching and mutating methods live.
 */
class Client {
	constructor() {
		this.cart = new CartResource();
		this.customer = new CustomerResource();
	}
}

export default Client;