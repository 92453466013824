import React from "react";
import thunk from "redux-thunk";
import rootReducer from "./src/reducers";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { Provider } from "react-redux";
import reducerRegistry from "./reducerRegistry";
import { IS_PRODUCTION } from "utils/constants";

export default ({ element }) => {
	const store = createStore(rootReducer, IS_PRODUCTION ? applyMiddleware(thunk) : composeWithDevTools(applyMiddleware(thunk)));
	reducerRegistry.setChangeListener((reducers) => {
		store.replaceReducer(combine(reducers));
	});
	return <Provider store={store}>{element}</Provider>;
};
