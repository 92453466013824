import { combineReducers } from "redux";
import profile from "./account/profile";
import subscription from "./account/subscription";
import cart from "./cart";
import filters from "./filters";
import global from "./global";

const reducers = combineReducers({
	account: combineReducers({ profile, subscription }),
	cart,
	filters,
	global
});

export default reducers;
