import { SET_GLOBAL_DATA, SET_HAS_OFFER_BAR } from "utils/action-types";
import ShopifyClient from "shopify/client";

const initialState = {
	isClient: false,
	cartBonus: null,
	hasOfferBar: false,
	shopifyClient: new ShopifyClient(),
};

const globalReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_GLOBAL_DATA:
			return {
				...state,
				...action.payload,
				isClient: true,
			};
		case SET_HAS_OFFER_BAR:
			return {
				...state,
				hasOfferBar: action.payload,
			};

		default:
			return state;
	}
};

export default globalReducer;
