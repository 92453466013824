/* eslint-disable max-lines */

export const CustomerAddressFragment = `
  fragment CustomerAddressFragment on CustomerAddress {
    id
    address1
    address2
    city
    company
    country
    firstName
    lastName
    phoneNumber
    province
    zip
    zoneCode
  }
`;

export const CartUserErrorFragment = `
  fragment CartUserErrorFragment on CartUserError {
    code
    field
    message
  }
`;

export const MoneyV2Fragment = `
fragment MoneyV2Fragment on MoneyV2 {
  amount
  currencyCode
}`;


export const VariantFragment = `
fragment VariantFragment on ProductVariant {
  id
  title
  price {
    amount
    currencyCode
  }
  sellingPlanAllocations (first: 20) {
    nodes {
      sellingPlan {
        id
        name
      }
    }
  }
  weight
  weightUnit
  available: availableForSale
  sku
  compareAtPrice {
    amount
    currencyCode
  }
  image {
    id
    src: url
    altText
  }
}`;

export const VariantWithProductFragment = `
  ${VariantFragment}
  fragment VariantWithProductFragment on ProductVariant {
    ...VariantFragment
    product {
      id
      handle
      title
      productType
    }
  }`;

export const CartFragment = `
  ${VariantWithProductFragment}
  ${MoneyV2Fragment}
  fragment CartFragment on Cart {
    id,
    attributes {
      key
      value
    }
    checkoutUrl,
    totalQuantity,
    createdAt,
    cost {
      subtotalAmount {
        amount
        currencyCode
      }
    }
    discountCodes {
      applicable
      code
    }
    discountAllocations {
      discountedAmount {
        amount
        currencyCode
      }
      ... on CartAutomaticDiscountAllocation {
        discountedAmount {
          ...MoneyV2Fragment
        }
        title
      }
      ... on CartCodeDiscountAllocation {
        discountedAmount {
          ...MoneyV2Fragment
        }
        code
      }
      ... on CartCustomDiscountAllocation {
        discountedAmount {
          ...MoneyV2Fragment
        }
        title
      }
    }
    buyerIdentity {
      email
      countryCode
    },
    lines(first: 250) {
      nodes {
        id
        variant: merchandise {
          ... on ProductVariant {
            ...VariantWithProductFragment
          }
        }
        sellingPlanAllocation {
          sellingPlan {
            id
            name
          }
        }
        quantity
        attributes {
          key
          value
        }
        discountAllocations {
          discountedAmount {
            ...MoneyV2Fragment
          }
          ... on CartCodeDiscountAllocation {
            discountedAmount {
              ...MoneyV2Fragment
            }
            code
          }
          ... on CartCustomDiscountAllocation {
            discountedAmount {
              ...MoneyV2Fragment
            }
            title
          }
        }
      }
    }
  }
`;