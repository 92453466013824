import wrapWithProvider from "./store";
import pageWrapper from "./wrapPageElement";
export const wrapRootElement = wrapWithProvider;
import initLoyaltyLion from "scripts/initLoyaltyLion";
import "./src/styles/global.css";

//Called when the user changes routes, including on the initial load of the app
export const onRouteUpdate = () => {
	if (!window.loyaltylion) {
		initLoyaltyLion();
		window.loyaltylion.init({ token: `${process.env.GATSBY_LION_TOKEN}` });
	}
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
	const isModal = location?.state?.modal;
	const preventUpdateScroll = location?.state?.preserveScroll;

	if (isModal || preventUpdateScroll)
		return false;

	return true;
};

export const wrapPageElement = pageWrapper;