import {
	cartQuery, cartCreateMutation, cartLinesAddMutation, cartLinesUpdateMutation, cartLinesRemoveMutation,
	cartAttributesUpdateMutation, cartDiscountCodesUpdateMutation
} from "./queries";
import handleCartMutation from "./handle-cart-mutation";
import axios from "axios";
import { SHOPIFY_STOREFRONT_TOKEN, SHOPIFY_DOMAIN, SHOPIFY_API_VERSION } from "utils/constants";

class CartResource {
	constructor() {
		this.storefrontApiCall = null;
		this.client = axios.create({
			baseURL: `https://${SHOPIFY_DOMAIN}/api/${SHOPIFY_API_VERSION}/graphql.json`,
			headers: {
				"Content-Type": "application/json",
				"X-Shopify-Storefront-Access-Token": SHOPIFY_STOREFRONT_TOKEN,
			},
		});
		this.storefrontApiCall = (query, variables) =>
			this.client.post("", { query, variables })
				.then(response => {
					const data = { ...Object.values(response.data.data)[0] };
					if (response.data?.errors)
						data.errors = response.data.errors;

					return data;
				})
				.catch(error => {
					console.log(error);
					return null;
				});
	}

	async fetch(id) {
		return this.storefrontApiCall(cartQuery, { id });
	}

	async create(cartInput = {}) {
		return this.storefrontApiCall(cartCreateMutation, { cartInput })
			.then(handleCartMutation);
	}

	async addLineItems(cartId, lines) {
		return this.storefrontApiCall(cartLinesAddMutation, { cartId, lines })
			.then(handleCartMutation);
	}

	async updateLineItems(cartId, lines) {
		return this.storefrontApiCall(cartLinesUpdateMutation, { cartId, lines })
			.then(handleCartMutation);
	}

	async removeLineItems(cartId, lineIds) {
		return this.storefrontApiCall(cartLinesRemoveMutation, { cartId, lineIds })
			.then(handleCartMutation);
	}

	async updateAttributes(cartId, attributes) {
		return this.storefrontApiCall(cartAttributesUpdateMutation, { cartId, attributes })
			.then(handleCartMutation);
	}

	async updateDiscountCodes(cartId, discountCodes) {
		return this.storefrontApiCall(cartDiscountCodesUpdateMutation, { cartId, discountCodes })
			.then(handleCartMutation);
	}
}

export default CartResource;